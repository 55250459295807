<template>
  <div class="container">
    <div
      v-if="practice"
      @click="backToEditPractice"
      class="leftQuestionCountTip"
    >
      <i class="fas fa-caret-left" />
      <span style="margin-left: 12px">
        {{ $t("practice.form.count") }}{{ practice.exam.questions.length }}
      </span>
    </div>
    <Breadcrumb v-if="practice">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'EditPractices',
              params: { practiceId: practice.id }
            }"
          >
            {{ `Edit ${practice.exam.title}` }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>{{ $t("pageTitle.AddQuestionsToPractice") }}</h1>
    <el-alert v-if="practice" type="success" :closable="false">
      <div style="font-size: 16px">
        {{ $t("practice.form.addPracticeToFullQuestion1")
        }}{{ practice.exam.title
        }}{{ $t("practice.form.addPracticeToFullQuestion2") }}<br />
        {{ $t("practice.form.alreadyAddQuestion1") }}
        <span style="color:red; font-size: 20px">{{
          practice.exam.questions.length
        }}</span>
        {{ $t("practice.form.alreadyAddQuestion2")
        }}{{ $t("practice.form.mostAddQuestion1") }}
        <span style="color:#3c763d; font-size: 20px">75</span>
        {{ $t("practice.form.mostAddQuestion2") }}
      </div>
    </el-alert>
    <div class="search">
      <el-row type="flex" style="align-items: center">
        <el-col :span="4">TOEFL:</el-col>
        <el-col :span="20">
          <el-checkbox-group
            :value="search.originalSubjects"
            @input="subjects => setSearch({ subjects })"
          >
            <el-checkbox-button
              :key="toeflSubject.id"
              v-for="toeflSubject in toeflSubjects"
              :label="toeflSubject.id"
            >
              {{ toeflSubject.title }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row type="flex" style="align-items: center">
        <el-col :span="4">ACT:</el-col>
        <el-col :span="20">
          <el-checkbox-group
            :value="search.originalSubjects"
            @input="subjects => setSearch({ subjects })"
          >
            <el-checkbox-button
              :key="actSubject.id"
              v-for="actSubject in actSubjects"
              :label="actSubject.id"
            >
              {{ actSubject.title }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row type="flex" style="align-items: center">
        <el-col :span="4">New Digital SAT:</el-col>
        <el-col :span="20">
          <el-checkbox-group
            v-model="search.originalSubjects"
            @input="subjects => setSearch({ subjects })"
          >
            <el-checkbox-button
              :key="satSubject.id"
              v-for="satSubject in newSatSubjects"
              :label="satSubject.id"
            >
              {{ satSubject.title }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row type="flex" style="align-items: center">
        <el-col :span="4">Old Paper-Based SAT:</el-col>
        <el-col :span="20">
          <el-checkbox-group
            v-model="search.originalSubjects"
            @input="subjects => setSearch({ subjects })"
          >
            <el-checkbox-button
              :key="satSubject.id"
              v-for="satSubject in satSubjects"
              :label="satSubject.id"
            >
              {{ satSubject.title }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row type="flex" style="align-items: center">
        <el-col :span="4"
          >Tags
          <router-link
            :to="{
              name: 'tags',
              query: { tagType: examTagTypes[0].key }
            }"
          >
            <i class="fas fa-edit action-icon" /> </router-link
          >:</el-col
        >
        <el-col :span="20">
          <el-select
            :value="search.tags"
            @input="
              tags => {
                setSearch({ tags });
              }
            "
            multiple
            filterable
            placeholder="請選擇"
            style="width:100%"
          >
            <el-option-group
              v-for="tagType in examTagTypes"
              :key="tagType.key"
              :label="tagType.name"
            >
              <el-option
                v-for="tag in tags.filter(({ type }) => type === tagType.key)"
                :key="tag.id"
                :label="helper.displayI18nText($i18n.locale, tag.name)"
                :value="String(tag.id)"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <el-tabs v-model="tagActiveName" type="card" v-if="false">
            <el-tab-pane
              :key="tagType.key"
              v-for="tagType in examTagTypes"
              :name="tagType.key"
            >
              <template slot="label">
                {{ tagType.name }}
                <router-link
                  :to="{
                    name: 'tags',
                    query: { tagType: tagType.key }
                  }"
                >
                  <i class="fas fa-edit action-icon" />
                </router-link>
              </template>
              <!-- <el-checkbox-group
                style="line-height: 0px"
                :value="search.tags"
                @input="
                  tags => {
                    setSearch({ tags });
                  }
                "
                size="mini"
              >
                <el-checkbox-button
                  v-for="tag in tags.filter(({ type }) => type === tagType.key)"
                  :key="tag.id"
                  :label="String(tag.id)"
                >
                  {{ helper.displayI18nText($i18n.locale, tag.name) }}
                </el-checkbox-button>
              </el-checkbox-group> -->
              <div>
                <el-select
                  :key="tagType.key"
                  :value="search.tags"
                  @input="
                    tags => {
                      setSearch({ tags });
                    }
                  "
                  multiple
                  filterable
                  placeholder="請選擇"
                  style="width:100%"
                >
                  <el-option
                    v-for="tag in tags.filter(
                      ({ type }) => type === tagType.key
                    )"
                    :key="tag.id"
                    :label="helper.displayI18nText($i18n.locale, tag.name)"
                    :value="String(tag.id)"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row type="flex" style="align-items: center">
        <el-col :span="4">Keyword:</el-col>
        <el-col :span="16">
          <el-input
            ref="filterInput"
            placeholder="keyword"
            v-model="search.search"
          >
            <i slot="prepend" class="el-icon-search" />
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            @click="setKeywordToQuery"
            type="primary"
            style="width: 100%"
          >
            Search
          </el-button>
        </el-col>
      </el-row>
      <hr class="separate-line" />
    </div>
    <div
      style="min-height:300px;margin-bottom: 20px;"
      v-loading="questions === null"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div v-if="questions">
        <div v-if="questions.length > 0" class="questions">
          <el-alert v-if="questions.length" type="success" :closable="false">
            <div style="font-size: 16px">
              <span style="color:#3c763d; font-size: 20px">{{
                totalQuestionCount
              }}</span>
              result(s) from found! Showing
              <span style="color:#3c763d; font-size: 20px">
                {{
                  `${(page - 1) * pageSize + 1} - ${filteredEndQuestionCount}`
                }} </span
              >:
            </div>
          </el-alert>
          <div class="d-flex justify-content-center mb-4 mt-4">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="page"
              @current-change="page => setSearch({ page })"
              :total="totalQuestionCount"
            />
          </div>
          <div
            v-for="question in questions"
            :key="`${question.testType}${question.question_id}`"
            style="margin: 8px 0px"
          >
            <Question
              :testType="question.testType"
              :testTypeId="question.testTypeId"
              :sectionId="question.sectionId"
              :question="questionAdapter(question)"
              :disableDelete="false"
              @reload="fetchQuestions"
            />
            <div style="margin-top: -30px">
              <el-button
                v-if="question.examQuestionId"
                key="delete"
                type="danger"
                style="width: 100%"
                @click="() => removeQuestion(question.examQuestionId)"
              >
                {{ $t("practice.form.remove") }}
                <i key="delete" class="fas fa-trash-alt" />
              </el-button>
              <el-button
                v-else
                key="create"
                type="primary"
                style="width: 100%"
                @click="
                  () => addQuestions(question.testType, question.question_id)
                "
              >
                {{ $t("practice.form.add") }}
                <i key="plus" class="fa fa-plus" />
              </el-button>
            </div>
            <hr />
          </div>
          <div class="d-flex justify-content-center mb-4">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="page"
              @current-change="page => setSearch({ page })"
              :total="totalQuestionCount"
            />
          </div>
        </div>
        <div v-else>
          <el-empty description="No Data"></el-empty>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-4">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="page"
          @current-change="page => setSearch({ page })"
          :total="totalQuestionCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import questionAdapter from "@/components/tests/QuestionAdapter";
import Question from "@/components/tests/Question.vue";
import Breadcrumb from "@/components/Breadcrumb";
import questionsApi from "@/apis/questions";
import practiceApi from "@/apis/practices";
import tagApi from "@/apis/tag";
import Practice from "@/views/practices/Practice";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb, Question },
  data() {
    return {
      practice: null,
      search: {
        test_types: [],
        originalSubjects: [],
        subjects: [],
        tags: [],
        search: ""
      },
      questions: null,
      tags: [],
      page: 1,
      pageSize: 50,
      totalQuestionCount: 0,
      tagActiveName: "question_grammar"
    };
  },
  computed: {
    helper() {
      return helper;
    },
    pageTitle() {
      return `${this.$t("pageTitle.AddQuestionsToPractice")}`;
    },
    actSubjects() {
      return [
        { id: "act-english", title: "English" },
        { id: "act-math", title: "Math" },
        { id: "act-reading", title: "Reading" },
        { id: "act-science", title: "Science" }
      ];
    },
    satSubjects() {
      return [
        { id: "sat-reading", title: "Reading" },
        { id: "sat-writing", title: "Writing" },
        { id: "sat-math", title: "Math" },
        { id: "sat-math_calculator", title: "Math Calculator" }
      ];
    },
    newSatSubjects() {
      return [
        { id: "sat-English", title: "English" },
        { id: "sat-math", title: "Math" }
      ];
    },
    toeflSubjects() {
      return [{ id: "toefl-reading", title: "Reading" }];
    },
    examTagTypes() {
      return [
        { name: "Gammar", key: "question_grammar" },
        { name: "Math", key: "question_math" },
        { name: "Reading", key: "question_reading" },
        { name: "Science", key: "question_science" }
      ];
    },
    questionAdapter() {
      return questionAdapter;
    },
    filteredEndQuestionCount() {
      const computedCount = (this.page - 1) * this.pageSize + this.pageSize;
      return computedCount > this.totalQuestionCount
        ? this.totalQuestionCount
        : computedCount;
    }
  },
  async created() {
    await this.fetchPractice();
    this.getTags();
    this.setQueryStringToSearchState();
    this.page = this.$route.query?.page || 1;
    this.fetchQuestions();
  },
  methods: {
    checkExistQuestion(questions) {
      return questions.map(question => {
        const questionId = `${question.question_type}_${question.question_id}`;
        const isExistQuestion = this.practice.exam.questions.find(
          practiceQuestion =>
            `${practiceQuestion.question_type}_${practiceQuestion.question_id}` ===
            questionId
        );
        if (isExistQuestion)
          return { ...question, examQuestionId: isExistQuestion.id };
        return { ...question, examQuestionId: null };
      });
    },
    setSearch(value) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, ...value }
      });
    },
    setQueryStringToSearchState() {
      const query = this.$route.query;
      Object.keys(query).forEach(key => {
        if (key === "subjects") {
          let testTypeMap = new Set();
          let subjectMap = new Set();
          let subjects = [];
          if (typeof query.subjects === "object") {
            subjects = query.subjects;
          } else if (typeof query.subjects === "string") {
            subjects = [query.subjects];
          }
          subjects.forEach(subject => {
            testTypeMap.add(subject.split("-")[0]);
            subjectMap.add(subject.split("-")[1]);
          });
          this.search = {
            ...this.search,
            originalSubjects: subjects,
            test_types: Array.from(testTypeMap),
            subjects: subjects
          };
        } else if (key === "tags") {
          if (typeof query.tags === "object") {
            this.search.tags = query.tags;
          } else if (typeof query.tags === "string") {
            this.search.tags = [query.tags];
          }
        } else {
          this.search = { ...this.search, [key]: query[key] };
        }
      });
    },
    setKeywordToQuery() {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, search: this.search.search }
      });
    },
    async fetchPractice() {
      const { practice } = await practiceApi.fetchPractice(
        this.$route.params.practiceId
      );
      this.practice = new Practice(practice);
    },
    async fetchQuestions() {
      // this.questions = null;
      const { data: questions, total } = await questionsApi.getQuestions({
        pageSize: this.pageSize,
        page: this.page,
        ...this.search
      });
      this.totalQuestionCount = total;
      this.questions = questions.map(question => ({
        ...question,
        id: question.question_id,
        order: question.question_order,
        testType: question.test_type,
        testTypeId: String(question.test_id),
        sectionId: String(question.section_id)
      }));
      this.questions = this.checkExistQuestion(this.questions);
    },
    async getTags() {
      const { tags } = await tagApi.fetchTags();
      this.tags = tags;
    },
    async addQuestions(questionType, questionId) {
      try {
        await practiceApi.addQuestions(this.$route.params.practiceId, {
          question_type: questionType.toLowerCase(),
          question_id: questionId
        });
        await this.fetchPractice();
        this.questions = this.checkExistQuestion(this.questions);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async removeQuestion(examQuestionId) {
      try {
        await practiceApi.removeQuestions(examQuestionId);
        await this.fetchPractice();
        this.questions = this.checkExistQuestion(this.questions);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    backToEditPractice() {
      this.$router.push({
        name: "EditPractices",
        params: { practiceId: this.$route.params.practiceId }
      });
    }
  }
};
</script>

<style>
.search {
  margin: 20px 0px;
}

.questions {
  margin: 20px 0px;
}

.leftQuestionCountTip {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: #42a16a;
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
